import React from 'react'
import { Link } from 'gatsby'
import Paper from '@material-ui/core/Paper'
import classNames from 'classnames'

import Layout from '../components/layout'
import Image from '../components/image'
import { button, paper, disabledLink, disabledPaper } from './home.module.scss'

const Button = props => (
  <Paper
    classes={{ root: props.disabled ? disabledPaper : paper }}
    elevation={4}
  >
    <Link
      {...props}
      className={classNames({ [button]: true, [disabledLink]: props.disabled })}
    />
  </Paper>
)

const visible = new Date() >= new Date('2018-12-10T14:30:00+01:00') || null

const IndexPage = () => (
  <Layout>
    <div
      style={{
        maxWidth: '300px',
        marginBottom: '1.45rem',
        margin: '30px auto',
      }}
    >
      <Image />
    </div>
    <div style={{}}>
      {/* <Button to="/quiz/" disabled>
        Quiz
      </Button> */}
      <Button to="/question/">Tchat</Button>
      {/* <Button to="/photos" disabled>
        Selfies
      </Button> */}
    </div>
  </Layout>
)

export default IndexPage
